import React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import mfkLogo from "../images/logos-01.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import mccLogo from "../images/logos-02.svg";

const Footer: React.FC = () => {
  return (
    <footer className='block w-full bg-gray-100 py-5'>
      <div className='flex flex-row items-center mx-5 max-w-5xl lg:mx-auto'>
        <hr className='my-3' />
        <div className='block w-1/2 sm:w-1/2 text-center font-secondary'>
          <span>© {new Date().getFullYear()}. Built by Moment.</span>
        </div>
        <div className='block w-1/2 sm:flex sm:flex-row'>
          <img
            src={mfkLogo}
            alt='Millenium Foundation Kosovo'
            className='block w-24 mx-auto'
            data-cy='mfk-logo'
          />
          <img
            src={mccLogo}
            alt='Millenium Challenge Corporation'
            className='block w-24 my-3 mx-auto'
            data-cy='mcc-logo'
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
