import React, { useState } from "react";
import { Link } from "gatsby";
import Transition from "./transition";

interface HeaderProps {
  siteTitle: string;
  showBreak?: boolean;
}

const Header: React.FC<HeaderProps> = ({ siteTitle, showBreak = false }) => {
  const [isHidden, setIsHidden] = useState(true);
  const menuItems = [
    { link: "#about", name: "About", id: Math.random().toString(32).substr(7) },
    {
      link: "/talk-data",
      name: "Talk Data",
      id: Math.random().toString(32).substr(7),
    },
    {
      link: "/platform",
      name: "Platform",
      cls: "bg-white starter font-extrabold",
      id: Math.random().toString(32).substr(7),
    },
  ];
  return (
    <header className='relative w-full bg-starter text-white p-3 sm:p-4 lg:p-12'>
      <div className='flex flex-row justify-between items-center mx-auto md:max-w-5xl'>
        <div className='inline-block'>
          <Link to='/'>
            <h3 className='font-primary text-3xl font-xbold' data-cy='logo'>
              {siteTitle}
            </h3>
          </Link>
        </div>
        <div className='relative sm:hidden'>
          <div className='inline-block'>
            <button
              type='button'
              onClick={() => setIsHidden(!isHidden)}
              /* eslint-disable-next-line max-len */
              className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none transition duration-100 ease-in-out'
              aria-label='Open the menu'
              name='main-menu'
              data-cy='hamburger-menu-button'
            >
              <svg
                className='h-6 w-6'
                stroke='currentColor'
                fill='none'
                viewBox='0 0 24 24'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>
            </button>
          </div>
          <Transition
            show={!isHidden}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <div
              className={`${
                isHidden ? "hidden" : "absolute"
              } origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg`}
              data-cy='hamburger-nav'
            >
              <div className='rounded-md bg-white shadow-xs'>
                <div className='py-1'>
                  {menuItems.map(item => (
                    <Link
                      to={item.link}
                      className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${item.cls}`}
                      key={item.id}
                      id={item.id}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Transition>
        </div>
        <div className='hidden sm:block' data-cy='full-nav'>
          <div className='flex flex-row'>
            {menuItems.map(item => (
              <Link
                to={item.link}
                className={`font-secondary block px-4 py-2 text-sm leading-5 text-white hover:starter focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${item.cls}`}
                key={item.id}
                id={item.id}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <hr
        className={`${
          showBreak ? "block" : "hidden"
        } sm:hidden bg-gray-200 my-3`}
      />
    </header>
  );
};

export default Header;
