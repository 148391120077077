import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Header from "./header";
import Footer from "./footer";

import "../tailwind.css";
import "../main.css";

interface LayoutProps {
  showBreak?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ showBreak = false, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div className='relative'>
      <Header siteTitle={data.site.siteMetadata.title} showBreak={showBreak} />
      <div className='inline-block mx-auto w-full'>
        <main className='w-full'>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
